import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "./firebase";

// Vuetify --08/09/2023 experiment
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// tastifications
import toast from "vue-toastification";
import 'vue-toastification/dist/index.css'


const vuetify = createVuetify({
  components,
  directives
});

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

createApp(App)
  .use(vuetify)
  .use(router)
  .use(store)
  .use(toast)
  .component('Datepicker', Datepicker)
  .mount("#app");

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = firebase;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export { app };
