import axios from "axios";

export default {
  namespaced: true,
  state: {
    supsEmployees: [],
    empTimesheets: [],
    loggedSupervisor: [],
    companyName: [],
  },
  mutations: {
    SAVE_EMPLOYEES(state, data) {
      state.supsEmployees = data;
    },
    SAVE_TIMESHEETS(state, data) {
      state.empTimesheets = data;
    },
    SAVE_SUPERVISOR(state, data) {
      state.loggedSupervisor = data;
    },
    SAVE_COMPANY(state, data) {
      state.companyName = data;
    }
  },
  actions: {
    getEmployees({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_NODE_API + "/employees/supervisor-employees/" + params
          )
          .then(response => {
            resolve(commit("SAVE_EMPLOYEES", response.data.data)); //this is fucking stupid, but sequalize
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    getEmployeeTime({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_NODE_API + "/timesheet/employee-timesheet") // + params)
          .then(response => {
            resolve(commit("SAVE_TIMESHEETS", response.data.data)); //this is fucking stupid, but sequalize
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    getLoggedSupervisor({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_NODE_API + "/employees/supervisor-user/" + params
          )
          .then(response => {
            resolve(commit("SAVE_SUPERVISOR", response.data.data)); //this is fucking stupid, but sequalize
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    getCompanyName({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_NODE_API + "/timesheet/timesheet-company/")
          .then(response => {
            resolve(commit("SAVE_COMPANY", response.data.data));
          })
          .catch(error => {
            reject(error.response.data)
          })
      })
    }
  },
  modules: {}
};
