<template>
  <div>
    <h2 class="text-center">Time and Attendance</h2>  
    <div class="login-page">

    <transition name="fade">
      <div class ="wallpaper-register" />
      </transition>
      <div class="wallpaper-register" />
      <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
          <div class="card login">
          <form @submit.prevent="login" class="form-group">
            <h2 class="mb-3">
              Login
            </h2>
            <div class="input">
              <label for="email">Email address</label>
              <input
                class="form-control"
                type="text"
                name="email"
                placeholder="email@adress.com"
              >
            </div>
            <div class="input">
              <label for="password">Password</label>
              <input
                class="form-control"
                type="password"
                name="password"
                placeholder="password123"
              >
            </div>
            <v-btn
              id="login_button"
              type="submit"
              class="mt-4 btn-pers"
            >
              Login
            </v-btn>
          </form>
          </div>
          </div> 
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login(submitEvent) {
      this.email = submitEvent.target.elements.email.value;
      this.password = submitEvent.target.elements.password.value;

      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
           (errorCode);
           (errorMessage);
          let alert_1 = document.querySelector("#alert_1");
          alert_1.classList.remove("d-none");
          alert_1.innerHTML = errorMessage;
           (alert_1);
        });
    },
  },
};
</script>
